import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import {
    Attachment,
    UserAttachment,
    WebSocketFeedback,
    WebSocketFormatedContent,
    WebSocketMessage
} from "../models/webSocketMessage";
import { WebSocketService } from "./web-socket.service";
import { LanguageService } from "./language.service";
import { HttpService } from "./http.service";
import { ConfigService } from "./config.service";
import { MessageDisplayFormat } from "../models/messageDisplayFormat";
import { VisibilityService } from "./visibility.service";
import { EventService } from "./event.service";
import { AttachmentDto } from "../dtos/attachmentDto";
import { AlertService } from "./alert.service";
import { RelatedQuestion } from "../models/relatedQuestion";
import { PredefinedFeedback, PredefinedMessage, QuickLink } from "../models/quickLink";
import { MultimediaService } from "./multimedia.service";
import { v4 as uuidv4 } from "uuid";

@Injectable({
  providedIn: 'root'
})
export class MessageService {
    messageIndex: number | null = null;
    messages: MessageDisplayFormat[] = []
    messageRelatedQuestions: BehaviorSubject<RelatedQuestion[]> = new BehaviorSubject<RelatedQuestion[]>([]);
    fileUrlPattern = /\[([^\]]+)\]\((http:\/\/[^\s]+|https:\/\/[^\s]+)\)/g;

    constructor(
        private config: ConfigService,
        private event: EventService,
        private http: HttpService,
        private language: LanguageService,
        private socket: WebSocketService,
        private visibility: VisibilityService,
        private alert: AlertService,
        private multimedia: MultimediaService) { }

    // getters
    getMessages(): MessageDisplayFormat[] {
        return this.messages
    }

    getMessageIndex(): number | null {
        return this.messageIndex ?? null;
    }

    getMessage(id: string) {
        return this.messages.find(m => m.id === id)
    }

    getMessageRelatedQuestion(): Observable<RelatedQuestion[]> {
        return this.messageRelatedQuestions;
    }

    // setters
    setMessage (author: string, message: WebSocketMessage) {
        // Prepare AI attachments to display
        if (author == 'ai') {
            if (!message.content) {
                message.formattedContent = this.formatContent("This message does not have assigned content.");
            }

            message.attachments = [];
            message.attachments = this.extractAttachmentsFromContent(message);

            // ! is markdown for indicate image link
            message.content = message.content.replace(/!\[([^\]]+)\]\((http:\/\/[^\s]+|https:\/\/[^\s]+)\)/g, '[$1]($2)');
            // Replace file URL patterns with links
            message.content = message.content.replaceAll(this.fileUrlPattern, '<a href="$2" target="_blank">$1</a>');
            // Formate message content because of csv tables showing into html file
            // FormattedContent has type of text or csv and value of content
            message.formattedContent = this.formatContent(message.content);
        }

        let m = this.createMessageDisplay(author, message)

        if (author == 'ai') {
            const urlRegex = /href="([^"]+)"/g;
            const matches = [...message.content.matchAll(urlRegex)];
            if (matches.length > 0) {
                //m.hyperlink = matches[matches.length - 1][1];
                m.hyperlink = matches.map(match => match[1]);
            } else {
                m.hyperlink = null;
            }
        }

        this.messages.push(m);

        // TODO remove from here
        if (author == "ai") {
            this.visibility.hideComponent("avatar-loader");
        } else if (author == "client") {
            this.visibility.showComponent("avatar-loader");
        }

        this.event.scrollToBottomEvent.emit();
    }

    setMessageFeedback (index: number, feedback: boolean, feedbackContent?: string, feedbackReason?: string) {
        const message = this.findMessageByIndex(index);
        if (message) {
            message.feedback = feedback;
            this.messages[index] = message;

            if (message.isPredefined) {
                const feedback: PredefinedFeedback = {
                    feedbackReason: feedbackReason ?? "",
                    feedbackMessage: feedbackContent ?? "",
                    feedbackStatus: message.feedback
                }

                const feedbackPredefined: PredefinedMessage = {
                    id: message.id,
                    agentId: this.config.getAgentId(),
                    mode: "text",
                    query: message.predefinedQuery ?? "",
                    queryAudioUrl: null,
                    answer: message.content,
                    answerAudioUrl: null,
                    sentiment: null,
                    userId: this.config.getAnonymousId(),
                    userIdentity: this.config.getAgentName() ?? "",
                    channel: "WebSocket",
                    device: this.config.getDevice(),
                    feedback: feedback
                }

                this.sendPredefinedFeedback(feedbackPredefined).then();
            } else {
                const feedbackWS: WebSocketFeedback = {
                    type: "feedback",
                    feedback_id: message.id,
                    feedback_status: message.feedback,
                    feedback_message: feedbackContent ?? "",
                    feedback_reason: feedbackReason ?? ""
                }

                this.sendFeedback(feedbackWS).then();
            }
        }
    }

    setMessageIndex(index: number) {
        this.messageIndex = index;
    }

    setMessageRelatedQuestion(relatedQuestions: RelatedQuestion[]): void {
        return this.messageRelatedQuestions.next(relatedQuestions);
    }

    createMessageDisplay(author: string, message: WebSocketMessage) {
        // TODO dummy attachments to simulate related content
        /*const defaultAttachments: Attachment[] = [
            new Attachment("image", "assets/images/decoration.png", "decoration.png"),
            new Attachment("video", "assets/videos/video.mp4", "video.mp4"),
            new Attachment("video", "assets/videos/idle.mp4", "idle.mp4"),
            new Attachment("document", "assets/documents/sample.pdf", "sample.pdf"),
            new Attachment("image", "assets/images/cat.png", "cat.png")
        ];

        message.attachments = defaultAttachments;*/


        let messageObj: MessageDisplayFormat = {
            id: message.id ?? "",
            author: author,
            content: message.content,
            language: message.language,
            direction: this.language.getLanguage(message.language).direction,
            attachments: message.attachments,
            audioAnswerUrl: message.audioAnswer,
            hyperlink: null,
            formattedContent: message.formattedContent,
            currentRowsIndex: 0,
            dataIndex: 0,
            isPredefined: message.isPredefined,
            predefinedQuery: message.predefinedQuery ?? ""
        }

        return messageObj;
    }

    clearMessages () {
        this.messages = [];
    }

    extractAttachmentsFromContent(message: WebSocketMessage) {
        const attachments: Attachment[] = [];
        const matches = message.content.match(this.fileUrlPattern);

        if (matches) {
            matches.forEach((match) => {
                const url = match.match(/\((http:\/\/[^\s]+|https:\/\/[^\s]+)\)/)?.[1];
                if (url) {
                    const fileNameWithParams = url.split('/').pop();
                    const nameMatch = fileNameWithParams ? fileNameWithParams.split('?')[0] : fileNameWithParams;

                    if (nameMatch) {
                        const allowedExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'docx', 'txt', 'mp4'];
                        const fileExtension = nameMatch.split('.').pop()?.toLowerCase();

                        if (fileExtension && allowedExtensions.includes(fileExtension)) {
                            const type = this.multimedia.getAttachmentType(nameMatch);
                            const newAttachment = new Attachment(type, url, nameMatch ?? null);
                            attachments.push(newAttachment);
                        }
                    }
                }
            });
        }

        return attachments;
    }

    findMessageByIndex(index: number) {
        return this.messages.find((_, i) => i === index);
    }

    formatContent(content: string): WebSocketFormatedContent[] {
        const csvPattern = /```?csv\n([\s\S]*?)\n```/g;
        const formPattern = /\[CONTACT_FORM]/g;
        const parts: WebSocketFormatedContent[] = [];
        let lastIndex = 0;

        const addTextPart = (text: string) => {
            if (text) {
                parts.push({ type: 'text', value: text });
            }
        };

        content.replace(csvPattern, (match, csvContent, offset) => {
            addTextPart(content.slice(lastIndex, offset));
            lastIndex = offset + match.length;

            const rows = csvContent.split('\n').map((row: any) => row.split(',').map((cell: any) => cell.trim()));
            const headers = rows.shift() || [];
            parts.push({ type: 'csv', value: { headers, rows } });

            return match;
        });

        content.replace(formPattern, (match, offset) => {
            addTextPart(content.slice(lastIndex, offset));
            lastIndex = offset + match.length;
            parts.push({ type: 'form', value: null });

            return match;
        });

        addTextPart(content.slice(lastIndex));

        return parts;
    }

    async editMessage(index: number, content: string) {
        //this.setMessageRelatedQuestion([])

        const message = this.findMessageByIndex(index);
        if (message) {
            message.content = content;
            this.messages[index] = message;
            this.messages.splice(index + 1);

            const messageWS: WebSocketMessage = {
                type: "text",
                mode: "text",
                content: content,
                formattedContent: this.formatContent(content),
                language: this.language.getSelectedLanguage()?.locale,
                token: this.config.getToken(),
                device: this.config.getDevice(),
                isPredefined: false
            }

            this.visibility.showComponent("avatar-loader");

            this.event.scrollToBottomEvent.emit();
            await this.submitConversationRequest(messageWS);
        }
    }

    createAttachmentDisplay (files: any) {
        const attachments = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const newAttachment = new Attachment(file.type, file.url,file.name ?? null);
            attachments.push(newAttachment);
        }

        return attachments;
    }

    createAttachmentsDisplay (files: any) {
        const attachments = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const newAttachment = new Attachment(file.type, file.url, file.name);
            attachments.push(newAttachment);
        }

        return attachments;
    }

    async createConversationRequest (type: string, mode:string, currentInputValue: string, audioId?: string, files?: AttachmentDto[]) {
        this.visibility.showComponent("avatar-conversation")
        //this.setMessageRelatedQuestion([])
        let formContent;

        if (currentInputValue) {
            formContent = this.formatContent(currentInputValue);
        } else {
            formContent = [] as WebSocketFormatedContent[];
        }

        const message: WebSocketMessage = {
            type: type,
            mode: mode,
            content: currentInputValue,
            formattedContent: formContent,
            language: this.language.getSelectedLanguage()?.locale,
            token: this.config.getToken(),
            device: this.config.getDevice(),
            isPredefined: false
        }

        // Prepare client attachments to display
        message.attachments = [];
        if (files && files.length !== 0) {
            try {
                const attachmentResponses = await Promise.all(
                    files.map(file => this.processAttachment(file.file))
                );

                const allSuccessful = attachmentResponses.every(response => response.status === 200 && response.body === true);

                if (allSuccessful) {
                    message.attachments = this.createAttachmentsDisplay(files);

                    message.user_attachments = files.map((file) => {
                        const sanitizedFileName = file.file.name.replace(/\s+/g, '_');
                        return new UserAttachment(sanitizedFileName);
                    });
                } else {
                    message.attachments = [];
                    console.error('Not all attachments were processed successfully');
                }
            } catch (error) {
                console.error('Error while processing attachments:', error);
            }
        }

        if (type === "audio") {
            message.content = audioId ?? "not found";
        } else if (type === "text") {
            this.setMessage("client", message);
        }

        try {
            await this.submitConversationRequest(message);
        } catch (e: any) {
            console.log('Error while submit conversation request: ' + e)
        }
    }

    // TODO setup file for BE
    /*setUpFile (file: AttachmentDto): Attachment {
        const type = file.type;
        const name = file.name;
        const readableName = name.replace(/[-_]/g, ' ').replace(/\.[^/.]+$/, '');
        if (type === 'image') {
            message.content = `Here is a scan of my ${readableName}: ${name}`;
        } else if (type === 'video') {
            message.content = `Here is my ${readableName} video: ${name}`;
        } else if (type === 'document') {
            message.content = `Here is my ${readableName} document: ${name}`;
        }
        return { type: type, url: file.src };
    }*/

    createQuickLinkConversation (quickLink: QuickLink) {
        this.visibility.showComponent("avatar-conversation")
        const language = this.language.getSelectedLanguage().locale;

        const quickLinkMessage: WebSocketMessage = {
            type: 'text',
            mode: 'text',
            content: quickLink.subtopic,
            formattedContent: this.formatContent(quickLink.subtopic),
            language: language,
            token: this.config.getToken(),
            device: this.config.getDevice(),
            isPredefined: false
        }

        this.setMessage("client", quickLinkMessage);

        this.visibility.showComponent("avatar-loader");
        setTimeout(() => {
            this.visibility.hideComponent("avatar-loader");

            const aiMessage: WebSocketMessage = {
                id: uuidv4(),
                type: 'text',
                mode: 'text',
                content: quickLink.answer,
                formattedContent: this.formatContent(quickLink.answer),
                language: language,
                token: this.config.getToken(),
                device: this.config.getDevice(),
                isPredefined: true,
                predefinedQuery: quickLink.subtopic
            }

            console.log('QUICK LINK AI MESSAGE')
            console.log(aiMessage);

            this.setMessage("ai", aiMessage)
        }, 2000);
    }

    // events
    private async processAttachment(file: File) {
        try {
            const url = `https://storage-service.dev.exafysolutions.ae/storage/store/documents`;

            const formData = new FormData();
            formData.append("file", new File([file], encodeURIComponent(file.name), { type: file.type }));

            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setContent(formData)
                .createAttachment<any>()
            );

            return {
                body: response.body,
                status: response.status,
                message: response.message
            }
        } catch (error) {
            console.error('Error processing attachment:', error);
            throw error;
        }
    }

    async sendFeedback (feedback: WebSocketFeedback) {
        try {
            this.socket.sendFeedback(feedback);

            if (!feedback.feedback_status) {
                this.alert.showSucess('Feedback succesfully sent', 'Feedback sucessfully provided.');
            }
        } catch (e: any) {
            throw new Error("Could not push feedback");
        }
    }

    async submitConversationRequest (data: WebSocketMessage) {
        try {
            this.socket.sendMessage(data);
        } catch (e: any) {
            if (e instanceof ProgressEvent || e.status === 0) {
                const lang = this.language.getSelectedLanguage().locale;

                const message: WebSocketMessage = {
                    id: "lost-net",
                    type: 'text',
                    mode: 'text',
                    content: this.language.getDesignTranslation(lang).typography.lostNet,
                    formattedContent: this.formatContent(this.language.getDesignTranslation(lang).typography.lostNet),
                    language: lang,
                    token: this.config.getToken(),
                    device: this.config.getDevice(),
                    isPredefined: false
                }

                this.setMessage("ai", message)
                return;
            }
            throw e;
        }
    }
    async submitAudioData (audioData: File) {
        const formData = new FormData();
        formData.append("audio", audioData);

        try {
            return await lastValueFrom(
                this.http
                .setHost(`${this.config.getHttpScheme()}://${this.config.getHost()}/storage/stt-voice`)
                .setMethod("POST")
                .setHeaders({
                    "Authorization": `Bearer ${this.config.getAnonymousId()}`
                })
                .setContent(formData)
                .create()
            );
        } catch (e: any) {
            throw new Error(`could not submit audio data: ${e}`)
        }
    }

    async sendPredefinedFeedback (predefinedMessage: PredefinedMessage) {
        try {
            return await lastValueFrom(
                this.http
                .setHost(`${this.config.getHttpScheme()}://${this.config.getAnalyticsHost()}/store`)
                .setMethod("POST")
                .setHeaders({
                    "Authorization": `Bearer ${this.config.getAnonymousId()}`,
                    "Content-Type": "application/json"
                })
                .setContent(predefinedMessage)
                .create()
            );
        } catch (e: any) {
            throw new Error(`could not submit predefined feedback: ${e}`)
        }
    }
}
