<div class="box-message">
    <div class="messages-container" [ngClass]="{'talk-started': talkStarted}">
        <div class="message-wrapper" *ngFor="let message of messages; let i = index; last as isLast; first as isFirst"
             (mouseenter)="onMouseEnter(i)" (mouseleave)="onMouseLeave()">
            <div *ngIf="message.author === 'ai'" class="ai-message">
                <div class="ai-message-container">
                    <div class="image-container">
                        <img [src]="hubLogo" class="ai-icon" alt="AI icon"/>
                    </div>
                    <div class="ai-message-content">
                        <div style="display: inline-block; width: 100%;">
                            <div class="ai-text-message">
                                <div class="table-container">
                                    <div *ngFor="let part of message.formattedContent">
                                        <span *ngIf="part.type === 'text'" [innerHTML]="part.value | boldText"></span>
                                        <div *ngIf="part.type === 'csv'" class="csv-table-wrapper">
                                            <table class="csv-table" [@slideTable]="message.dataIndex">
                                                <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th *ngFor="let header of part.value.headers">{{ header }}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr *ngFor="let row of getRowsToShow(part.value.rows, message); let index = index">
                                                    <td>{{ message.currentRowsIndex + index + 1 }}</td>
                                                    <td *ngFor="let cell of row">{{ cell }}</td>
                                                </tr>
                                                </tbody>
                                                <tr *ngIf="part.value.rows.length > rowsToShow">
                                                    <td colspan="100%" class="csv-table-buttons">
                                                        <div class="button-wrapper">
                                                            <button class="left-button" (click)="showTopRows(message)">
                                                                <app-arrow-back></app-arrow-back>
                                                            </button>
                                                            <button class="right-button" (click)="showBottomRows(part.value.rows, message)">
                                                                <app-arrow-next></app-arrow-next>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div *ngIf="part.type === 'form'" class="form-container">
                                            <app-contact-form></app-contact-form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <app-hyperlink [hyperlinkInput]="message.hyperlink"></app-hyperlink>
                            <div  class="message-feedback"
                                  [class.show]="hoveredMessageIndex === i ||
                                  (message.feedback === true || message.feedback === false) ||
                                  (isLast && !isFirst)" >
                                <div class="feedback-container">
                                    <div class="copy-message-container">
                                        <button id="id-copy" type="button" class="copy-button" (click)="copyTextMessage(message.content, i)">
                                            <div class="copy-inside-button" *ngIf="!isMessageCopied[i]">
                                                <app-copy></app-copy>
                                                <p>{{ tooltipCopy }}</p>
                                            </div>
                                            <div class="copy-inside-button" *ngIf="isMessageCopied[i]">
                                                <app-correct></app-correct>
                                                <p>{{ copiedLabel }}</p>
                                            </div>
                                        </button>
                                        <!--<p *ngIf="!isMessageCopied[i]" class="button-tooltip">{{ tooltipCopy }}</p>-->
                                    </div>
                                    <div *ngIf="existCsv(message.content)" class="download-message-container">
                                        <button id="id-download" type="button" class="download-button" (click)="downloadCsv(message.content, i)">
                                            <div class="copy-inside-button" *ngIf="!isCsvDonwloaded[i]">
                                                <app-download></app-download>
                                                <p>{{ tooltipDownload }}</p>
                                            </div>
                                            <div class="copy-inside-button" *ngIf="isCsvDonwloaded[i]">
                                                <app-correct></app-correct>
                                                <p>{{ downloadedLabel }}</p>
                                            </div>
                                        </button>
                                        <!--<p *ngIf="!isCsvDonwloaded[i]" class="button-tooltip">{{ tooltipDownload }}</p>-->
                                    </div>
                                    <div *ngIf="isAnalitycEnabled" class="like-message-container">
                                        <button *ngIf="message.feedback === true || message.feedback === undefined" id="id-like"
                                                type="button" class="like-button" (click)="positiveReaction(i)"
                                                [ngClass]="{'clicked': isLikeButtonClicked[i]}"
                                                [disabled]="isLikeButtonClicked[i] || message.id === '' ? true : null"
                                        >
                                            <app-like></app-like>
                                        </button>
                                        <p class="button-tooltip">{{ tooltipLike }}</p>
                                    </div>
                                    <div *ngIf="isAnalitycEnabled" class="dislike-message-container">
                                        <button *ngIf="message.feedback === false || message.feedback === undefined" id="id-dislike"
                                                type="button" class="dislike-button" data-bs-toggle="modal" data-bs-target="#feedbackModal"
                                                data-target=".bd-example-modal-lg" (click)="negativeReaction(i)"
                                                [ngClass]="{'clicked': isDislikeButtonClicked[i]}"
                                                [disabled]="isDislikeButtonClicked[i] || message.id === '' ? true : null"
                                        >
                                            <app-dislike></app-dislike>
                                        </button>
                                        <p class="button-tooltip">{{ tooltipDislike }}</p>
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div *ngIf="message.attachments && message.attachments.length > 0" class="related-content-scroll-container">
                            <app-related-content [attachments]="message.attachments" [author]="message.author"></app-related-content>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="message.author === 'client'" [ngClass]="{'edit-started': isEditing && editingMessageIndex === i}" class="client-message">
                <div class="margin-client-message">
                    <div class="triangle-message">
                        <div class="triangle"></div>
                        <div class="client-text-message">
                            <div class="client-content-message">
                                <div *ngIf="message.attachments && message.attachments.length > 0" class="client-media-content-container">
                                    <app-related-content [attachments]="message.attachments" [author]="message.author"></app-related-content>
                                </div>
                                <p [innerHTML]="message.content | boldText"></p>
                            </div>
                            <div class="editing-message-container">
                                <div class="editing-message-flex">
                                    <form action="#0">
                                    <textarea appAutoResize id="id-edit-textarea" spellcheck="false" type="text" name="edit-textarea"
                                              [(ngModel)]="editingMessageContent" (keyup.enter)="sendEditedMessage(i)"
                                              (keydown.enter)="!messageFilled() && $event.preventDefault()">
                                    </textarea>
                                    </form>
                                    <div class="edit-buttons-container">
                                        <button id="id-cancel-edit" class="cancel-button" type="button" (click)="cancelEditing()">{{ cancelButton }}</button>
                                        <button id="id-send-edit" class="send-button" type="button" (click)="sendEditedMessage(i)"
                                                [disabled]="!messageFilled()">{{ sendButton }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="edit-message-container">
                        <div [class.show]="hoveredMessageIndex === i" class="edit-message-wrapper">
                            <button id="id-edit-message" type="button" class="edit-message-button" (click)="editClientMessage(i, message.content)" [disabled]="!isInteractionAllowed" >
                                <app-edit></app-edit>
                            </button>
                            <p class="button-tooltip">{{ tooltipEdit }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
