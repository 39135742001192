import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Subscription } from "rxjs";
import { Clipboard } from "@angular/cdk/clipboard";
import { saveAs } from 'file-saver';
import { trigger, transition, style, animate, query, group } from '@angular/animations';

import { MessageService } from "../../../core/services/message.service";
import { EventService } from "../../../core/services/event.service";
import { LanguageService } from "../../../core/services/language.service";
import { MessageDisplayFormat } from "../../../core/models/messageDisplayFormat";
import { InteractionService } from "../../../core/services/interaction.service";
import { ConfigService } from "../../../core/services/config.service";

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrl: './message.component.scss',
    animations: [
        trigger('slideTable', [
            transition(':increment', [
                group([
                    query('tbody', [
                        style({ transform: 'translateX(100%)', opacity: 0 }),
                        animate('450ms ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
                    ]),
                    query('tbody', [
                        style({ transform: 'translateX(0)', opacity: 1 }),
                        animate('450ms ease-in', style({ transform: 'translateX(-100%)', opacity: 0 }))
                    ])
                ])
            ]),
            transition(':decrement', [
                group([
                    query('tbody', [
                        style({ transform: 'translateX(-100%)', opacity: 0 }),
                        animate('450ms ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
                    ]),
                    query('tbody', [
                        style({ transform: 'translateX(0)', opacity: 1 }),
                        animate('450ms ease-in', style({ transform: 'translateX(100%)', opacity: 0 }))
                    ])
                ])
            ])
        ])
    ]
})
export class MessageComponent implements OnInit, OnDestroy {
    hubLogo: string = "assets/icons/hub-logo.svg"
    tooltipCopy: string = 'Copy';
    tooltipLike: string = 'Good response';
    tooltipDislike: string = 'Bad response';
    tooltipEdit: string = 'Edit message';
    tooltipDownload: string = 'Download CSV';
    cancelButton: string = 'Cancel';
    sendButton: string = 'Send';
    copiedLabel: string = 'Copied';
    downloadedLabel: string = 'Downloaded';

    hoveredMessageIndex: number | null = null;

    editingMessageIndex: number | null = null;
    isMessageCopied: boolean[] = [];
    isCsvDonwloaded: boolean[] = [];
    messages: MessageDisplayFormat[] = [];
    isDislikeButtonClicked: boolean[] = [];
    isLikeButtonClicked: boolean[] = [];
    talkStarted: boolean = false;
    isEditing: boolean = false;
    editingMessageContent!: string;
    isInteractionAllowed: boolean = false;

    translateSubscription!: Subscription;
    talkSubscription!: Subscription;
    interactionSubscription!: Subscription;
    editSubscription!: Subscription;

    relatedContentTitle: string = 'Related Content:';
    rowsToShow: number = 10; // Initially show 10 rows

    constructor(
        private event: EventService,
        protected message: MessageService,
        private language: LanguageService,
        private clipboard: Clipboard,
        private interaction: InteractionService,
        private config: ConfigService
    ) { }

    ngOnInit(): void {
        this.messages = this.message.getMessages();

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.event.dislikeButtonClicked.subscribe(index => {
            this.isDislikeButtonClicked[index] = false;
        });

        this.event.feedbackSubmitted.subscribe(index => {
            this.isDislikeButtonClicked[index] = true;
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });

        this.editSubscription = this.event.getStartedEdit().subscribe(isEditing => {
            this.isEditing = isEditing;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            Object.assign(this, translate.typography);
        });
    }

    get isAnalitycEnabled(): boolean {
        return this.config.getAnalyticEnabled();
    }

    // Method to get the rows to display based on rowsToShow
    getRowsToShow(rows: any[], message: MessageDisplayFormat): any[] {
        // Return only the rows starting from currentRowsIndex and up to 10 rows
        return rows.slice(message.currentRowsIndex, message.currentRowsIndex + this.rowsToShow);
    }

    showBottomRows(rows: any[], message: MessageDisplayFormat): void {
        if (message.currentRowsIndex + this.rowsToShow < rows.length) {
            message.currentRowsIndex += this.rowsToShow;
            message.dataIndex++;
        }
    }

    showTopRows(message: MessageDisplayFormat): void {
        if (message.currentRowsIndex - this.rowsToShow >= 0) {
            message.currentRowsIndex -= this.rowsToShow;
            message.dataIndex--;
        }
    }

    getCSVDownload(content: string): string[] {
        const urls: string[] = [];

        for (const match of this.matchAllCSV(content)) {
            if (match[1]) {
                const csvContent = match[1];
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                urls.push(url);
            }
        }

        return urls;
    }

    downloadCsv(content: string, i: number) {
        const csvUrls = this.getCSVDownload(content);

        csvUrls.forEach((url, index) => {
            saveAs(url, `data-${index + 1}.csv`);
            this.isCsvDonwloaded[i] = true;

            setTimeout(() => {
                this.isCsvDonwloaded[i] = false;
            }, 3000);
        });
    }

    existCsv(content: string): boolean {
        return /```?csv\n([\s\S]*?)\n```/.test(content);
    }

    matchAllCSV(content: string) {
        const csvPattern = /```?csv\n([\s\S]*?)\n```/g;
        return  content.matchAll(csvPattern);
    }

    onMouseEnter(index: number): void {
        this.hoveredMessageIndex = index;
    }

    onMouseLeave(): void {
        this.hoveredMessageIndex = null;
    }

    copyTextMessage(content: string, index: number) {
        const cleanedContent = content
            .replace(/\[CONTACT_FORM]/g, '')
            .replace(/```?csv\n([\s\S]*?)\n```/g, (match, csvContent) => {
                const rows = csvContent.split('\n').map((row: string) => row.split(',').map((cell: string) => cell.trim()));
                return rows.map((row: any) => row.join(' ')).join('\n') + '\n';
            })
            .replace(/\*\*(.*?)\*\*/g, '$1');

        this.clipboard.copy(cleanedContent);
        this.isMessageCopied[index] = true;

        setTimeout(() => {
            this.isMessageCopied[index] = false;
        }, 3000);
    }

    positiveReaction(index: number) {
        this.message.setMessageFeedback(index, true);
        this.isLikeButtonClicked[index] = true;
    }

    negativeReaction(index: number) {
        this.message.setMessageIndex(index);
        this.event.blurEfectEvent.emit(true);
        this.event.dislikeButtonClicked.emit(index);
    }

    editClientMessage(index: number, content: string) {
        this.event.setStartedEdit(true);
        this.editingMessageIndex = index;
        this.editingMessageContent = content;
    }

    async sendEditedMessage(index: number) {
        if (!this.messageFilled()) return;
        await this.message.editMessage(index, this.editingMessageContent);
        this.resetFields();
    }

    cancelEditing() {
        this.event.setStartedEdit(false);
        this.editingMessageIndex = null;
    }

    messageFilled(): boolean {
        return !!(this.editingMessageContent && this.editingMessageContent.trim() !== "");
    }

    resetFields() {
        this.editingMessageContent = "";
        this.event.setStartedEdit(false);
        this.editingMessageIndex = null;
    }

    ngOnDestroy() {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.talkSubscription) { this.talkSubscription.unsubscribe(); }
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
        if (this.editSubscription) { this.editSubscription.unsubscribe(); }
    }
}
