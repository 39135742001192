import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { InteractionService } from "../../../core/services/interaction.service";
import { MessageService } from "../../../core/services/message.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { AnimationService } from "../../../core/services/animation.service";
import { LanguageService } from "../../../core/services/language.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit, OnDestroy {
    newChatButton = '';
    isInteractionAllowed!: boolean;
    isNewChatButtonVisible = false;

    interactionSubscription!: Subscription;
    translateSubscription!: Subscription;
    visibilitySubscription!: Subscription;

    constructor(
        private interaction: InteractionService,
        private message: MessageService,
        private visibility: VisibilityService,
        private animation: AnimationService,
        private language: LanguageService) { }

    ngOnInit(): void {
        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.newChatButton = translate.typography.newChat;
        });

        this.visibilitySubscription = this.visibility.getVisibility("avatar-conversation").subscribe((isVisible) => {
            this.isNewChatButtonVisible = isVisible;
        });
    }

    newChat() {
        this.message.clearMessages();
        this.visibility.hideComponent('avatar-conversation');
        this.animation.destroyAnimationApp();
    }

    ngOnDestroy(): void {
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.visibilitySubscription) { this.visibilitySubscription.unsubscribe(); }
    }
}
