import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from "rxjs";

import { ConfigService } from "./config.service";
import { HiperLink } from "../models/hiperLink";
import { HttpService } from "./http.service";
import { PredefinedMessage } from "../models/quickLink";

@Injectable({
    providedIn: 'root'
})
export class MiscService {
    constructor(private http: HttpService, private config: ConfigService) { }

    async fetchHiperLinkInfo(hiperlinkUrl: string) {
        const url = `${this.config.getHttpScheme()}://${this.config.getHost()}/misc/metadata?url=${hiperlinkUrl}`;

        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("GET")
                .setHeaders({
                    "Authorization": `Bearer ${this.config.getAnonymousId()}`
                })
                .create()
            );

            return {
                status: response.status,
                body: response.body as HiperLink
            }
        } catch (e: any) {
            return {
                status: e.status,
                message: e.message,
                body: {} as HiperLink
            };
        }
    }

    async sendContactForm (form: any) {
        const url = `${this.config.getHttpScheme()}://${this.config.getHost()}/misc/email`;

        try {
            return await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setHeaders({
                    "Authorization": `Bearer ${this.config.getAnonymousId()}`,
                    "Content-Type": "application/json"
                })
                .setContent(form)
                .create()
            );
        } catch (e: any) {
            throw new Error(`could not submit contact form: ${e}`)
        }
    }
}
