<div class="contact-form-container">
    <form *ngIf="!isFormSent" [formGroup]="contactForm">
        <div class="form-row">
            <h2>{{ contactFormLabel }}</h2>
        </div>
        <div class="form-row">
            <div class="form-col">
                <input id="id-first-name" type="text" name="first-name" [class.error]="isFieldInvalid('firstName')"
                       [placeholder]="firstNameLabel" formControlName="firstName" required>
                <div class="mat-error-container" *ngIf="isFieldInvalid('firstName')">
                    <mat-error *ngIf="getContactFormElement('firstName')?.hasError('required')">
                        {{ errorFirstNameRequiredMessage }}
                    </mat-error>
                </div>
            </div>
            <div class="form-col">
                <input id="id-last-name" type="text" name="last-name" [class.error]="isFieldInvalid('lastName')"
                       [placeholder]="lastNameLabel" formControlName="lastName" required>
                <div class="mat-error-container" *ngIf="isFieldInvalid('lastName')">
                    <mat-error *ngIf="getContactFormElement('lastName')?.hasError('required')">
                        {{ errorLastNameRequiredMessage }}
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-col">
                <input id="id-email" type="text" name="email" [class.error]="isFieldInvalid('email')"
                       [placeholder]="emailLabel" formControlName="email" required [email]="true">
                <div class="mat-error-container" *ngIf="isFieldInvalid('email')">
                    <mat-error *ngIf="getContactFormElement('email')?.hasError('required')">
                        {{ errorEmailRequiredMessage }}
                    </mat-error>
                    <mat-error *ngIf="getContactFormElement('email')?.hasError('email')">
                        {{ errorInvalidEmailMessage }}
                    </mat-error>
                </div>
            </div>
            <div class="form-col">
                <div class="custom-dropdown">
                    <button class="dropdown-button" (click)="toggleIamDropdown()" appOutsideClick
                            (outsideClick)="closeIamDropdown()">
                        <div class="content-description">
                            <p class="list-description">{{ iamLabel }}</p>
                            <span>{{ iAmOption }}</span>
                        </div>
                        <button *ngIf="!isIamDropdownOpen" id="id-down-i-am" type="button" class="arrow-button">
                            <app-arrow-down></app-arrow-down>
                        </button>
                        <button *ngIf="isIamDropdownOpen" id="id-top-i-am" type="button" class="arrow-button">
                            <app-arrow-top></app-arrow-top>
                        </button>
                    </button>
                    <div class="dropdown-content" *ngIf="isIamDropdownOpen">
                        <div class="dropdown-item" [class.selected]="iAm === iAmOption" *ngFor="let iAm of iAmOptions"
                             (click)="selectIamOption(iAm)" >{{ iAm }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row-single">
            <div class="custom-dropdown">
                <button class="dropdown-button" (click)="toggleIamInquiringDropdown()" appOutsideClick
                        (outsideClick)="closeIamInquiringDropdown()">
                    <div class="content-description">
                        <p class="list-description">{{ iamInquiringLabel }}</p>
                        <span>{{ iAmInquiringOption }}</span>
                    </div>
                    <button *ngIf="!isIamInquiringDropdownOpen" id="id-down-inquiring" type="button" class="arrow-button">
                        <app-arrow-down></app-arrow-down>
                    </button>
                    <button *ngIf="isIamInquiringDropdownOpen" id="id-top-inquiring" type="button" class="arrow-button">
                        <app-arrow-top></app-arrow-top>
                    </button>
                </button>
                <div class="dropdown-content" *ngIf="isIamInquiringDropdownOpen">
                    <div class="dropdown-item" [class.selected]="iAmInquiring === iAmInquiringOption"
                         *ngFor="let iAmInquiring of iAmInquiringOptions"
                         (click)="selectIamInquiringOption(iAmInquiring)" >{{ iAmInquiring }}
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row-single">
            <textarea id="id-form-message" type="text" name="message" [class.error]="isFieldInvalid('message')"
                      [placeholder]="messageLabel" formControlName="message" required></textarea>
            <div class="mat-error-container" *ngIf="isFieldInvalid('message')">
                <mat-error *ngIf="getContactFormElement('message')?.hasError('required')">
                    {{ errorMessageRequiredMessage }}
                </mat-error>
            </div>
        </div>
        <div class="form-row">
            <button id="id-submit-form-button" class="submit-form-button" type="button"
                    [disabled]="!isFormValid" (click)="submitForm()"> {{ submitButtonLabel }}
            </button>
        </div>
    </form>
    <div *ngIf="isFormSent" class="form-sent-container">
        <div class="form-sent-message">
            <div class="form-sent-title">
                <img src="assets/icons/check.svg" alt="Sent icon">
                <h2>{{ formSentTitle }}</h2>
            </div>
            <p>{{ formSentDescription }}</p>
        </div>
    </div>
</div>
