import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-plus',
    template: `
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9.50006L17 9.50023" stroke="#6B43C9" stroke-width="2" stroke-linecap="square"/>
            <path d="M9 1.5L9.00016 17.5" stroke="#6B43C9" stroke-width="2" stroke-linecap="square"/>
        </svg>
    `
})
export class PlusComponent {
}
