import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-edit',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path d="M15.508 2.35175L14.1482 0.992004C13.4923 0.335999 12.4249 0.335999 11.7688 0.992004L0.891144 11.8697C0.790479 11.9704 0.721879 12.0986 0.6939 12.2382L0.0140028 15.6375C-0.033253 15.8739 0.0407312 16.1183 0.211246 16.2888C0.347822 16.4253 0.531797 16.5 0.721109 16.5C0.768173 16.5 0.815477 16.4954 0.862492 16.486L4.26178 15.8061C4.40139 15.7782 4.5296 15.7095 4.63026 15.6089L15.508 4.73123C15.508 4.73123 15.508 4.73123 15.508 4.73118C16.164 4.07522 16.164 3.00781 15.508 2.35175ZM3.7649 14.4347L1.64036 14.8597L2.06533 12.7352L10.9189 3.88139L12.6186 5.58106L3.7649 14.4347ZM14.4882 3.71145L13.6384 4.56129L11.9387 2.86161L12.7885 2.01183C12.8822 1.91808 13.0347 1.91804 13.1285 2.01178L14.4882 3.37153C14.582 3.46522 14.582 3.61776 14.4882 3.71145Z" [attr.fill]="stroke"/>
        </svg>
    `,
    styles: [`
        :host {
            --stroke-edit-color: #C3C2C5;
        }
        svg path {
            stroke: var(--stroke-edit-color);
        }
    `]
})
export class EditComponent {
    @Input() stroke: string = 'var(--stroke-edit-color)';
}
