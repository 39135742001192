import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltip, MatTooltipModule } from "@angular/material/tooltip";
import { MatButton, MatButtonModule } from "@angular/material/button";

import { LandingComponent } from "../pages/landing/landing.component";
import { RelatedContentComponent } from "./components/related-content/related-content.component";
import { ShortcutsComponent } from "./components/shortcuts/shortcuts.component";
import { MessageComponent } from "./components/message/message.component";
import { LanguagesComponent } from "./components/languages/languages.component";
import { IntroductionComponent } from "./components/introduction/introduction.component";
import { HeaderComponent } from "./components/header/header.component";
import { ConversationControlsComponent } from "./components/conversation-controls/conversation-controls.component";
import { RelatedQuestionsComponent } from "./components/related-questions/related-questions.component";
import { AccountComponent } from "./components/account/account.component";
import { ContentModalComponent } from "./components/content-modal/content-modal.component";
import { CharacterComponent } from "./components/character/character.component";
import { SafePipe } from "./pipes/safe.pipe";
import { ConversationComponent } from "./components/conversation/conversation.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { PlayComponent } from "./svgs/play.component";
import { LikeComponent } from "./svgs/like.component";
import { EditComponent } from "./svgs/edit.component";
import { DislikeComponent } from "./svgs/dislike.component";
import { CorrectComponent } from "./svgs/correct.component";
import { CopyComponent } from "./svgs/copy.component";
import { CloseComponent } from "./svgs/close.component";
import { ImageComponent } from "./svgs/image.component";
import { StopComponent } from "./svgs/stop.component";
import { SoundwaveComponent } from "./components/soundwave/soundwave.component";
import { BarComponent } from "./svgs/bar.component";
import { ArrowBackComponent } from "./svgs/arrow-back.component";
import { ArrowNextComponent } from "./svgs/arrow-next.component";
import { MicrophoneComponent } from "./svgs/microphone.component";
import { AttachmentComponent } from "./svgs/attachment.component";
import { EnterComponent } from "./svgs/enter.component";
import { AutoResizeDirective } from "./directives/autoresize.directive";
import { DocumentComponent } from "./svgs/document.component";
import { BoldTextPipe } from "./pipes/bold.pipe";
import { RouterModule, Routes } from "@angular/router";
import { LostNetworkComponent } from "./components/lost-network/lost-network.component";
import { ReportIssueComponent } from './components/report-issue/report-issue.component';
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FadeInOutDirective } from "./directives/fade-in-out.directive";
import { HyperlinkComponent } from "./components/hyperlink/hyperlink.component";
import { QuickLinksComponent } from "./components/quick-links/quick-links.component";
import { ArrowTopComponent } from "./svgs/arrow-top.component";
import { ArrowDownComponent } from "./svgs/arrow-down.component";
import { DownloadComponent } from "./svgs/download.component";
import { PlusComponent } from "./svgs/plus.component";
import { ContactFormComponent } from "./components/contact-form/contact-form.component";
import { MatError, MatFormField, MatFormFieldModule } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { OutsideClickDirective } from "./directives/outside-click.directive";

const routes: Routes = [
    { path: '', component: LandingComponent }
];

@NgModule({
	declarations: [
		LandingComponent,
		RelatedContentComponent,
		ShortcutsComponent,
		MessageComponent,
		LanguagesComponent,
		IntroductionComponent,
		ConversationControlsComponent,
		RelatedQuestionsComponent,
		AccountComponent,
		ContentModalComponent,
		CharacterComponent,
		SafePipe,
		ConversationComponent,
		FeedbackComponent,
		LoaderComponent,
		SoundwaveComponent,
		AutoResizeDirective,
		DocumentComponent,
		BoldTextPipe,
		ReportIssueComponent,
		NavbarComponent,
		HeaderComponent,
        FadeInOutDirective,
        HyperlinkComponent,
        QuickLinksComponent,

		//svgs
		PlayComponent,
		LikeComponent,
		EditComponent,
		DislikeComponent,
		CorrectComponent,
		CopyComponent,
		CloseComponent,
		ImageComponent,
		StopComponent,
		BarComponent,
		ArrowBackComponent,
		ArrowNextComponent,
		MicrophoneComponent,
		AttachmentComponent,
		EnterComponent,
		LostNetworkComponent,
        ArrowTopComponent,
        ArrowDownComponent,
        DownloadComponent,
        PlusComponent,
        ContactFormComponent,
        OutsideClickDirective
	],
	exports: [
		RelatedQuestionsComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		NgOptimizedImage,
		MatTooltipModule,
        ReactiveFormsModule, // for using formControlName into input fields
        MatFormField,
        MatInput,
        MatError,
        MatFormFieldModule,
        MatTooltip,
		MatButtonModule,
		MatButton,
		RouterModule,
		RouterModule.forChild(routes)
	]
})
export class ComponentModule {}
