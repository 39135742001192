import { environment } from '../environments/environment';
import { QuickLinkTopic } from "./core/models/quickLink";


//let userSettingsService = new UserSettingsService();

/*export const appConfig: ApplicationConfig = {
    providers: [provideRouter(routes)]
};*/

// global constants
export const GLOBAL_CONSTANTS = {
    APP_TITLE: 'Design4Ui',
    API_ENDPOINT: environment.type === 'production' ? 'https://api.production.com' : 'https://api.development.com',
};
export const THEME_CONFIG = {
    AVAILABLE_THEMES: ['light', 'dark'],
    DEFAULT_THEME: 'light'
    //DEFAULT_THEME: userSettingsService.getTheme()
};

export const LOCALE_CONFIG = {
    AVAILABLE_LANGUAGES: ['en', 'es', 'fr', 'de'],
    DEFAULT_LANGUAGE: 'en'
};

export const LANG_CONFIG = [
    {
        name: 'عربي',
        icon: '../assets/languages/arabic.svg',
        shortName: 'AR',
        locale: 'ar-SA',
        direction: 'rtl',
        selected: false,
    },
    {
        //name: 'Chinese',
        name: '中國人',
        icon: '../assets/languages/chinese.svg',
        shortName: 'ZH',
        locale: 'zh-CN',
        direction: 'ltr',
        selected: false,
    },
    {
        name: 'English',
        icon: '../assets/languages/english.svg',
        shortName: 'EN',
        locale: 'en-US',
        direction: 'ltr',
        selected: true,
    },
    {
        //name: 'French',
        name: 'Français',
        icon: '../assets/languages/french.svg',
        shortName: 'FR',
        locale: 'fr-FR',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'German',
        name: 'Deutsch',
        icon: '../assets/languages/german.svg',
        shortName: 'DE',
        locale: 'de-DE',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'Russian',
        name: 'Русский',
        icon: '../assets/languages/russian.svg',
        shortName: 'RU',
        locale: 'ru-RU',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'Spanish',
        name: 'Español',
        icon: '../assets/languages/spanish.svg',
        shortName: 'ES',
        locale: 'es-ES',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'Turkish',
        name: 'Türkçe',
        icon: '../assets/languages/turkish.svg',
        shortName: 'TR',
        locale: 'tr-TR',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'Serbian',
        name: 'Srpski',
        icon: '../assets/languages/serbian.svg',
        shortName: 'SR',
        locale: 'sr-RS',
        direction: 'ltr',
        selected: false,
    },
    {
        //name: 'Serbian Cyrillic',
        name: 'Српски',
        icon: '../assets/languages/serbian.svg',
        shortName: 'SR-CYRL',
        locale: 'sr-Cyrl-RS',
        direction: 'ltr',
        selected: false,
    }
];

export const QUICK_LINKS_EN = [
    {
        topic: 'I am a Startup',
        quickLinks: [
            {
                subtopic: 'Explore the Hub71 programs',
                answer: 'Discover the diverse startup programs at Hub71, designed to accelerate growth and provide valuable resources. Learn more here: [Explore Hub71 Startup Programs](https://www.hub71.com/i-am-a-startup#programsSection).'
            },
            {
                subtopic: 'Browse list of investors',
                answer: 'Find a list of investors who are part of the Hub71 community. Browse the list here: [Discover Investors in the Hub71 Community](https://www.hub71.com/investors).'
            },
            {
                subtopic: 'Know more about the Access program',
                answer: 'Learn about the Access program, a tailored program for early-stage startups. Find out more here: [Explore Hub71 Access Program](https://www.hub71.com/program/access-program).'
            },
            {
                subtopic: 'Apply to the Access program',
                answer: 'Apply to the Access program, a tailored program for early-stage startups. Find out more here: [Apply to Hub71 Access Program](https://www.hub71.com/program/access-program/apply).'
            },
            {
                subtopic: 'Know more about the Hub71+ Digital Assets program',
                answer: 'Learn about the Hub71+ Digital Assets program, designed to support startups in the digital assets space. Find out more here: [Explore Hub71 Digital Access Program](https://www.hub71.com/program/hub71-plus-digital-assets).'
            },
            {
                subtopic: 'Apply to the Hub71+ Digital Assets program program',
                answer: 'Apply to the Hub71+ Digital Assets program, designed to support startups in the digital assets space. Find out more here: [Apply to Hub71 Digital Access Program](https://www.hub71.com/program/hub71-plus-digital-assets/apply).'
            },
            {
                subtopic: 'Know more about the Hub71+ ClimateTech program',
                answer: 'Learn about the Hub71+ ClimateTech program, designed to support startups in the climate tech space. Find out more here: [Explore Hub71 ClimateTech Program](https://www.hub71.com/program/hub71-plus-climatetech).'
            },
            {
                subtopic: 'Apply to the Hub71+ ClimateTech program',
                answer: 'Apply to the Hub71+ ClimateTech program, designed to support startups in the climate tech space. Find out more here: [Apply to Hub71 ClimateTech Program](https://www.hub71.com/program/hub71-plus-climatetech/apply).'
            },
            {
                subtopic: 'Attend a Hub71 info session',
                answer: 'Register for an upcoming Hub71 info session to learn more about the programs and opportunities available. Find out more here: [Register for Hub71 Info Session](https://www.hub71.com/latest-news/blog/register-your-interest-hub71-info-session).'
            }
        ]
    },
    {
        topic: 'I am an Investor',
        quickLinks: [
            {
                subtopic: 'Partner with Hub71',
                answer: 'Partner with Hub71 to support startups and contribute to the growth of the tech ecosystem. Apply here: [Apply to Partner with Hub71](https://www.hub71.com/partners/apply).'
            },
            {
                subtopic: 'Invest your expertise/be a mentor',
                answer: 'Share your expertise and mentor startups at Hub71. Sign up here: [Share your expertise and mentor startups at Hub71](https://airtable.com/appZUyoI3l6V3EQIK/shrTCSKi8hUrlyi7Z).'
            },
            {
                subtopic: 'Browse list of startups',
                answer: 'Discover the startups in the Hub71 community and explore investment opportunities. Browse the list here: [Discover Startups in the Hub71 Community](https://www.hub71.com/startups).'
            },
            {
                subtopic: 'Browse list of investors',
                answer: 'Find a list of investors who are part of the Hub71 community. Browse the list here: [Discover Investors in the Hub71 Community](https://www.hub71.com/investors).'
            }
        ]
    },
    {
        topic: 'I want to become a Partner',
        quickLinks: [
            {
                subtopic: 'Partner with Hub71',
                answer: 'Partner with Hub71 to support startups and contribute to the growth of the tech ecosystem. Apply here: [Apply to Partner with Hub71](https://www.hub71.com/partners/apply).'
            },
            {
                subtopic: 'Browse list of partners',
                answer: 'Discover the partners who are part of the Hub71 community. Browse the list here: [Discover Partners in the Hub71 Community](https://www.hub71.com/partners).'
            },
            {
                subtopic: 'Browse list of startups',
                answer: 'Discover the startups in the Hub71 community and explore partnership opportunities. Browse the list here: [Discover Startups in the Hub71 Community](https://www.hub71.com/startups).'
            }
        ]
    },
    {
        topic: 'Job Seekers',
        quickLinks: [
            {
                subtopic: 'Apply to jobs in the Hub71 community',
                answer: 'Explore job opportunities at Hub71 and apply to open positions. Find out more here: [Explore Job Opportunities at Hub71](https://jobs.hub71.com/jobs).'
            }
        ]
    },
    {
        topic: 'Service Providers',
        quickLinks: [
            {
                subtopic: 'Partner with Hub71 as a service provider',
                answer: 'Apply to become a partner at Hub71 and unlock new opportunities. Find out more here: [Apply to Become a Partner at Hub71](https://www.hub71.com/partners/apply).'
            }
        ]
    }
];

export const QUICK_LINKS_AR = [
    {
        topic: 'أنا شركة ناشئة',
        quickLinks: [
            {
                subtopic: 'استكشاف برامج Hub71 للشركات الناشئة',
                answer: 'اكتشف برامج الشركات الناشئة المتنوعة في Hub71، التي تهدف إلى تسريع النمو وتوفير الموارد القيمة. تعرف على المزيد هنا: [استكشاف برامج Hub71 للشركات الناشئة](https://www.hub71.com/i-am-a-startup#programsSection).'
            },
            {
                subtopic: 'تصفح قائمة المستثمرين',
                answer: 'ابحث عن قائمة المستثمرين الذين ينتمون إلى مجتمع Hub71. تصفح القائمة هنا: [اكتشاف المستثمرين في مجتمع Hub71](https://www.hub71.com/investors).'
            },
            {
                subtopic: 'اعرف المزيد عن برنامج Access',
                answer: 'تعرف على برنامج Access، وهو برنامج مخصص للشركات الناشئة في مراحلها المبكرة. اعرف المزيد هنا: [استكشاف برنامج Hub71 Access](https://www.hub71.com/program/access-program).'
            },
            {
                subtopic: 'التقديم إلى برنامج Access',
                answer: 'قدم إلى برنامج Access، وهو برنامج مخصص للشركات الناشئة في مراحلها المبكرة. اعرف المزيد هنا: [التقديم إلى برنامج Hub71 Access](https://www.hub71.com/program/access-program/apply).'
            },
            {
                subtopic: 'اعرف المزيد عن برنامج Hub71+ للأصول الرقمية',
                answer: 'تعرف على برنامج Hub71+ للأصول الرقمية، المصمم لدعم الشركات الناشئة في مجال الأصول الرقمية. اعرف المزيد هنا: [استكشاف برنامج Hub71 للأصول الرقمية](https://www.hub71.com/program/hub71-plus-digital-assets).'
            },
            {
                subtopic: 'التقديم إلى برنامج Hub71+ للأصول الرقمية',
                answer: 'قدم إلى برنامج Hub71+ للأصول الرقمية، المصمم لدعم الشركات الناشئة في مجال الأصول الرقمية. اعرف المزيد هنا: [التقديم إلى برنامج Hub71 للأصول الرقمية](https://www.hub71.com/program/hub71-plus-digital-assets/apply).'
            },
            {
                subtopic: 'اعرف المزيد عن برنامج Hub71+ لتقنية المناخ',
                answer: 'تعرف على برنامج Hub71+ لتقنية المناخ، المصمم لدعم الشركات الناشئة في مجال تكنولوجيا المناخ. اعرف المزيد هنا: [استكشاف برنامج Hub71 لتقنية المناخ](https://www.hub71.com/program/hub71-plus-climatetech).'
            },
            {
                subtopic: 'التقديم إلى برنامج Hub71+ لتقنية المناخ',
                answer: 'قدم إلى برنامج Hub71+ لتقنية المناخ، المصمم لدعم الشركات الناشئة في مجال تكنولوجيا المناخ. اعرف المزيد هنا: [التقديم إلى برنامج Hub71 لتقنية المناخ](https://www.hub71.com/program/hub71-plus-climatetech/apply).'
            },
            {
                subtopic: 'حضور جلسة معلومات Hub71',
                answer: 'سجل لجلسة معلومات Hub71 القادمة لتعرف المزيد عن البرامج والفرص المتاحة. اعرف المزيد هنا: [سجل لجلسة معلومات Hub71](https://www.hub71.com/latest-news/blog/register-your-interest-hub71-info-session).'
            }
        ]
    },
    {
        topic: 'أنا مستثمر',
        quickLinks: [
            {
                subtopic: 'شراكة مع Hub71',
                answer: 'شارك مع Hub71 لدعم الشركات الناشئة والمساهمة في نمو النظام البيئي التكنولوجي. قدم هنا: [التقديم لشراكة مع Hub71](https://www.hub71.com/partners/apply).'
            },
            {
                subtopic: 'استثمر خبرتك/كن مرشدًا',
                answer: 'شارك خبرتك وكن مرشدًا للشركات الناشئة في Hub71. سجل هنا: [شارك خبرتك وكن مرشدًا للشركات الناشئة في Hub71](https://airtable.com/appZUyoI3l6V3EQIK/shrTCSKi8hUrlyi7Z).'
            },
            {
                subtopic: 'تصفح قائمة الشركات الناشئة',
                answer: 'اكتشف الشركات الناشئة في مجتمع Hub71 واستكشف الفرص الاستثمارية. تصفح القائمة هنا: [اكتشاف الشركات الناشئة في مجتمع Hub71](https://www.hub71.com/startups).'
            },
            {
                subtopic: 'تصفح قائمة المستثمرين',
                answer: 'ابحث عن قائمة المستثمرين الذين ينتمون إلى مجتمع Hub71. تصفح القائمة هنا: [اكتشاف المستثمرين في مجتمع Hub71](https://www.hub71.com/investors).'
            }
        ]
    },
    {
        topic: 'أريد أن أصبح شريكًا',
        quickLinks: [
            {
                subtopic: 'شراكة مع Hub71',
                answer: 'شارك مع Hub71 لدعم الشركات الناشئة والمساهمة في نمو النظام البيئي التكنولوجي. قدم هنا: [التقديم لشراكة مع Hub71](https://www.hub71.com/partners/apply).'
            },
            {
                subtopic: 'تصفح قائمة الشركاء',
                answer: 'اكتشف الشركاء الذين ينتمون إلى مجتمع Hub71. تصفح القائمة هنا: [اكتشاف الشركاء في مجتمع Hub71](https://www.hub71.com/partners).'
            },
            {
                subtopic: 'تصفح قائمة الشركات الناشئة',
                answer: 'اكتشف الشركات الناشئة في مجتمع Hub71 واستكشف فرص الشراكة. تصفح القائمة هنا: [اكتشاف الشركات الناشئة في مجتمع Hub71](https://www.hub71.com/startups).'
            }
        ]
    },
    {
        topic: 'الباحثون عن العمل',
        quickLinks: [
            {
                subtopic: 'التقديم للوظائف في مجتمع Hub71',
                answer: 'استكشف الفرص الوظيفية في Hub71 وقدم لوظائف شاغرة. اعرف المزيد هنا: [استكشاف الفرص الوظيفية في Hub71](https://jobs.hub71.com/jobs).'
            }
        ]
    },
    {
        topic: 'مقدم الخدمة',
        quickLinks:  [
            {
                subtopic: 'تعاون مع Hub71 كمزود خدمات',
                answer: 'قدّم طلبك لتصبح شريكًا في Hub71 واغتنم فرصًا جديدة. اكتشف المزيد هنا: [قدّم طلب شراكة في Hub71](https://www.hub71.com/partners/apply).'
            }
        ]
    }
];


export const TRANSLATION_CONFIG = [
    {
        locale: "ar-SA",
        typography: {
            // account
            settings: "الإعدادات",
            logout: "تسجيل الخروج",
            // messages
            lostNet: "عذرًا، يبدو أنني معطل مؤقتًا. يرجى المحاولة مرة أخرى لاحقًا.",
            // landing page translation
            helloHeader: " مرحبًا، أنا",
            assistHeader: `كيف يمكنني مساعدتك اليوم؟`,
            // character translate
            bubbleText: [ "مرحبًا، أنا مساعدك الرقمي.", "انقر علي لبدء المكالمة." ],
            // language names translation
            /*languageNames: { "ar-SA": "عربي", "zh-CN": "صيني", "en-US": "إنجليزي", "fr-FR": "فرنسي",
                "de-DE": "ألمانية", "ru-RU": "الروسية", "es-ES": "اللغة", "tr-TR": "التركية", "sr-RS": "صربي",
                "sr-Cyrl-RS": "سربي السيريلية"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},
            // controls translation
            currentInputPlaceholder: "أنا هنا للمساعدة. كيف يمكنني المساعدة؟",
            currentInputPlaceholderMobile: "اسأل سؤالاً...",
            // feedback translation
            placeholderText: 'اكتب ملاحظاتك الإضافية...',
            feedbackText: 'تقديم تعليقات إضافية',
            harmfulCheckboxText: 'هذا ضار/غير آمن',
            helpfulCheckboxText: 'هذا ليس مفيدا',
            trueCheckBoxText: 'هذا ليس صحيحا',
            buttonText: 'تقديم',
            // tooltips
            tooltipCopy: 'نسخ',
            copiedLabel: 'تم النسخ',
            tooltipDownload: 'تحميل',
            downloadedLabel: "تم التنزيل",
            tooltipDislike: 'استجابة سيئة',
            tooltipLike: 'استجابة جيدة',
            tooltipEdit: 'تحرير الرسالة',
            sendButton: 'إرسال',
            cancelButton: 'إلغاء',
            // related content
            relatedContentTitle: 'محتوى ذو صلة:',
            relatedQuestionsTitle: 'استكشاف المزيد',
            quickLinksTitle: 'روابط سريعة',
            quickLinks: QUICK_LINKS_AR,
            newChat: 'دردشة جديدة',
            // contact form
            contactFormLabel: 'نموذج الاتصال',
            firstNameLabel: 'الاسم الأول',
            lastNameLabel: 'اسم العائلة',
            emailLabel: 'البريد الإلكتروني',
            iamLabel: 'أنا',
            iamInquiringLabel: 'أنا أستفسر عن',
            messageLabel: 'رسالتك',
            submitButtonLabel: 'إرسال',
            errorFirstNameRequiredMessage: 'الاسم الأول مطلوب.',
            errorLastNameRequiredMessage: 'اسم العائلة مطلوب.',
            errorEmailRequiredMessage: 'البريد الإلكتروني مطلوب.',
            errorIAmRequiredMessage: 'التصنيف مطلوب.',
            errorIAmInquiringRequiredMessage: 'الاستفسار مطلوب.',
            errorMessageRequiredMessage: 'الرسالة مطلوبة.',
            errorInvalidEmailMessage: 'البريد الإلكتروني غير صالح.',
            formSentTitle: 'تم إرسال البريد الإلكتروني بنجاح!',
            formSentDescription: 'شكرًا لتواصلكم! لقد تم إرسال رسالتكم بنجاح إلى فريقنا. سيقوم ممثل من Hub71 بالرد عليكم في أقرب وقت ممكن.',
            iAmOptions: ['شركة ناشئة', 'مستثمر', 'شركة', 'حكومة', 'أكاديمي', 'وسائل الإعلام', 'مستقل', 'آخر'],
            iAmInquiringOptions: ['برامج Hub71', 'تطبيقات Hub71', 'تقديم الخدمات', 'الشراكات/التعاون',
                'استفسارات أبوظبي/تأسيس الأعمال', 'فعاليات', 'الوظائف', 'الوفود', 'ملاحظات عامة', 'استفسار عام']
        }
    },
    {
        locale: "zh-CN",
        typography: {
            // account
            settings: "设置",
            logout: "退出",
            // messages
            lostNet: "抱歉，看来我暂时断开连接了。请稍后再试。",
            // landing page translation
            helloHeader: "你好，我是",
            assistHeader: `今天我能怎么帮你？`,
            // character translate
            bubbleText: [ "你好，我是你的数字助手。", "点击我开始通话。" ],
            // language names translation
            /*languageNames: { "ar-SA": "阿拉伯", "zh-CN": "中國人", "en-US": "英語", "fr-FR": "法語",
                "de-DE": "德文", "ru-RU": "俄文", "es-ES": "西班牙語", "tr-TR": "土耳其", "sr-RS": "Serbian (Lat)",
                "sr-Cyrl-RS": "Serbian (Cyr)"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},
            // controls translation
            currentInputPlaceholder: "我在这里帮忙。我能帮什么忙？",
            currentInputPlaceholderMobile: "提问...",
            // feedback translation
            placeholderText: '輸入您的附加回饋...',
            feedbackText: '提供額外的回饋',
            harmfulCheckboxText: '這是有害的/不安全的',
            helpfulCheckboxText: '這沒有幫助',
            trueCheckBoxText: '這不是真的',
            buttonText: '提交',
            // tooltips
            tooltipCopy: '复制',
            copiedLabel: '已复制',
            tooltipDownload: '下载',
            downloadedLabel: "已下载",
            tooltipDislike: '不好的回应',
            tooltipLike: '好的回应',
            tooltipEdit: '编辑消息',
            sendButton: '发送',
            cancelButton: '取消',
            // related content
            relatedContentTitle: '相关内容：',
            relatedQuestionsTitle: '探索更多',
            quickLinksTitle: '快捷链接',
            quickLinks: null,
            newChat: '新聊天',
            // contact form
            contactFormLabel: '联系表格',
            firstNameLabel: '名字',
            lastNameLabel: '姓',
            emailLabel: '电子邮件',
            iamLabel: '我是',
            iamInquiringLabel: '我正在查询',
            messageLabel: '您的消息',
            submitButtonLabel: '提交',
            errorFirstNameRequiredMessage: '名字是必需的。',
            errorLastNameRequiredMessage: '姓氏是必需的。',
            errorEmailRequiredMessage: '电子邮件是必需的。',
            errorIAmRequiredMessage: '“我是”是必需的。',
            errorIAmInquiringRequiredMessage: '“我在询问”是必需的。',
            errorMessageRequiredMessage: '消息是必需的。',
            errorInvalidEmailMessage: '电子邮件无效。',
            formSentTitle: '电子邮件已成功发送！',
            formSentDescription: '感谢您的联系！您的消息已成功发送至我们的团队。Hub71 的代表将尽快与您联系。',
            iAmOptions: ['设置', '投资者', '企业', '政府', '学术', '媒体', '自由职业者', '其他'],
            iAmInquiringOptions: ['Hub71 计划', 'Hub71 应用', '提供服务', '合作/协作',
                '阿布扎比/商业设立咨询', '活动', '职业', '代表团', '一般反馈', '一般查询']
        }
    },
    {
        locale: "en-US",
        typography: {
            // account
            settings: "Settings",
            logout: "Logout",
            // messages
            lostNet: "Apologies, it appears I'm momentarily disconnected. Please try again later.",
            // landing page translation
            helloHeader: "Hello, I am",
            assistHeader: `How may I assist you today?`,
            // character translate
            bubbleText: [ "Hello, I'm your Digital Assistant.", "Click on me to start a call." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arabic", "zh-CN": "Chinese", "en-US": "English", "fr-FR": "French",
                "de-DE": "German", "ru-RU": "Russian", "es-ES": "Spanish", "tr-TR": "Turkish", "sr-RS": "Serbian",
                "sr-Cyrl-RS": "Serbian Cyrillic"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},
            // controls translation
            currentInputPlaceholder: "I’m here to help. How can I assist?",
            currentInputPlaceholderMobile: "Ask a question...",
            // feedback translation
            placeholderText: 'Type your additional feedback...',
            feedbackText: 'Provide additional feedback',
            harmfulCheckboxText: 'This is harmful/unsafe',
            helpfulCheckboxText: 'This isn’t helpful',
            trueCheckBoxText: 'This isn’t true',
            buttonText: 'Submit',
            // tooltips
            tooltipCopy: 'Copy',
            copiedLabel: 'Copied',
            tooltipDownload: 'Download',
            downloadedLabel: "Downloaded",
            tooltipDislike: 'Bad response',
            tooltipLike: 'Good response',
            tooltipEdit: 'Edit message',
            sendButton: 'Send',
            cancelButton: 'Cancel',
            // related content
            relatedContentTitle: 'Related Content:',
            relatedQuestionsTitle: 'Explore More',
            quickLinksTitle: 'Quick Links',
            quickLinks: QUICK_LINKS_EN,
            newChat: 'New Chat',
            // contact form
            contactFormLabel: 'Contact Form',
            firstNameLabel: 'First Name',
            lastNameLabel: 'Last Name',
            emailLabel: 'Email',
            iamLabel: 'I am a/an',
            iamInquiringLabel: 'I am inquiring about',
            messageLabel: 'Your message',
            submitButtonLabel: 'Submit',
            errorFirstNameRequiredMessage: 'First name is required.',
            errorLastNameRequiredMessage: 'Last name is required.',
            errorEmailRequiredMessage: 'Email is required.',
            errorIAmRequiredMessage: 'I am a/an is required.',
            errorIAmInquiringRequiredMessage: 'I am inquiring is required.',
            errorMessageRequiredMessage: 'Message is required.',
            errorInvalidEmailMessage: 'Invalid email.',
            formSentTitle: 'Email successfully sent!',
            formSentDescription: 'Thank you for reaching out! Your message has been successfully sent to our team. A Hub71 representative will get back to you as soon as possible.',
            iAmOptions: ['Startup', 'Investor', 'Corporate', 'Government', 'Academic', 'Media', 'Freelancer', 'Other'],
            iAmInquiringOptions: ['Hub71 programs', 'Hub71 applications', 'Providing services', 'Partnerships/Collaborations',
                'Abu Dhabi/Business setup inquiries', 'Events', 'Careers', 'Delegations', 'General feedback', 'General inquiry']
        }
    },
    {
        locale: "fr-FR",
        typography: {
            // account
            settings: "Paramètres",
            logout: "Déconnexion",
            // messages
            lostNet: "Désolé, il semble que je sois momentanément déconnecté. Veuillez réessayer plus tard.",
            // landing page translation
            helloHeader: "Bonjour, je suis",
            assistHeader: `Comment puis-je vous aider aujourd'hui?`,
            // character translate
            bubbleText: [ "Bonjour, je suis votre assistant numérique.", "Cliquez sur moi pour commencer un appel." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arabe", "zh-CN": "Chinoise", "en-US": "Anglaise", "fr-FR": "Français",
                "de-DE": "Allemande", "ru-RU": "Russe", "es-ES": "Espagnole", "tr-TR": "Turque", "sr-RS": "Serbe",
                "sr-Cyrl-RS": "Serbe Cyrillique"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},
            // controls translation
            currentInputPlaceholder: "Je suis ici pour aider. Comment puis-je vous assister ?",
            currentInputPlaceholderMobile: "Poser une question...",
            // feedback translation
            placeholderText: 'Tapez vos commentaires supplémentaires...',
            feedbackText: 'Fournir des commentaires supplémentaires',
            harmfulCheckboxText: 'Ceci est dangereux/dangereux',
            helpfulCheckboxText: 'Ce n\'est pas utile',
            trueCheckBoxText: 'Ce n\'est pas vrai',
            buttonText: 'Soumettre',
            // tooltips
            tooltipCopy: 'Copier',
            copiedLabel: 'Copié',
            tooltipDownload: 'Télécharger',
            downloadedLabel: "Téléchargé",
            tooltipDislike: 'Mauvaise réponse',
            tooltipLike: 'Bonne réponse',
            tooltipEdit: 'Modifier le message',
            sendButton: 'Envoyer',
            cancelButton: 'Annuler',
            // related content
            relatedContentTitle: 'Contenu Connexe:',
            relatedQuestionsTitle: 'Explorer plus',
            quickLinksTitle: 'Liens Rapides',
            quickLinks: null,
            newChat: 'Nouveau Chat',
            // contact form
            contactFormLabel: 'Formulaire de contact',
            firstNameLabel: 'Prénom',
            lastNameLabel: 'Nom de famille',
            emailLabel: 'Email',
            iamLabel: 'Je suis',
            iamInquiringLabel: 'Je demande',
            messageLabel: 'Votre message',
            submitButtonLabel: 'Soumettre',
            errorFirstNameRequiredMessage: 'Le prénom est requis.',
            errorLastNameRequiredMessage: 'Le nom de famille est requis.',
            errorEmailRequiredMessage: 'L\'email est requis.',
            errorIAmRequiredMessage: 'Le champ "Je suis" est requis.',
            errorIAmInquiringRequiredMessage: 'Le champ "Je suis en train de m\'informer" est requis.',
            errorMessageRequiredMessage: 'Le message est requis.',
            errorInvalidEmailMessage: 'Email inval',
            formSentTitle: 'E-mail envoyé avec succès !',
            formSentDescription: 'Merci de nous avoir contactés ! Votre message a été envoyé avec succès à notre équipe. Un représentant de Hub71 vous répondra dans les plus brefs délais.',
            iAmOptions: ['Configuration', 'Investisseur', 'Entreprise', 'Gouvernement', 'Académique', 'Médias', 'Freelance', 'Autre'],
            iAmInquiringOptions: ['Programmes Hub71', 'Applications Hub71', 'Fourniture de services', 'Partenariats/Collaborations',
                'Demandes sur Abu Dhabi/Mise en place d\'entreprise', 'Événements', 'Carrières', 'Délégations', 'Commentaires généraux', 'Demande générale']
        }
    },
    {
        locale: "de-DE",
        typography: {
            // account
            settings: "Einstellungen",
            logout: "Abmelden",
            // messages
            lostNet: "Entschuldigung, es scheint, dass ich momentan getrennt bin. Bitte versuchen Sie es später erneut.",
            // landing page translation
            helloHeader: "Hallo, ich bin",
            assistHeader: `Wie kann ich Ihnen heute helfen?`,
            // character translate
            bubbleText: [ "Hallo, ich bin Ihr digitaler Assistent.", "Klicken Sie auf mich, um einen Anruf zu starten." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arabisch", "zh-CN": "Chinesisch", "en-US": "Englisch", "fr-FR": "Französisch", "de-DE": "Deutsch",
                "ru-RU": "Russisch", "es-ES": "Spanisch", "tr-TR": "Türkisch", "sr-RS": "Serbisch", "sr-Cyrl-RS": "Serbisch Kyrillisch"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},
            // controls translation
            currentInputPlaceholder: "Ich bin hier, um zu helfen. Wie kann ich helfen?",
            currentInputPlaceholderMobile: "Eine Frage stellen...",
            // feedback translation
            placeholderText: 'Geben Sie Ihr zusätzliches Feedback ein...',
            feedbackText: 'Geben Sie zusätzliches Feedback',
            harmfulCheckboxText: 'Das ist schädlich/unsicher',
            helpfulCheckboxText: 'Das ist nicht hilfreich',
            trueCheckBoxText: 'Das ist nicht wahr',
            buttonText: 'Einreichen',
            // tooltips
            tooltipCopy: 'Kopieren',
            copiedLabel: 'Kopiert',
            tooltipDownload: 'Herunterladen',
            downloadedLabel: "Heruntergeladen",
            tooltipDislike: 'Schlechte Antwort',
            tooltipLike: 'Gute Antwort',
            tooltipEdit: 'Nachricht bearbeiten',
            sendButton: 'Senden',
            cancelButton: 'Abbrechen',
            // related content
            relatedContentTitle: 'Verwandter Inhalt:',
            relatedQuestionsTitle: 'Mehr erkunden',
            quickLinksTitle: 'Schnelle Links',
            quickLinks: null,
            newChat: 'Neuer Chat',
            // contact form
            contactFormLabel: 'Kontaktformular',
            firstNameLabel: 'Vorname',
            lastNameLabel: 'Nachname',
            emailLabel: 'Email',
            iamLabel: 'Ich bin',
            iamInquiringLabel: 'Ich frage',
            messageLabel: 'Ihre Nachricht',
            submitButtonLabel: 'Einreichen',
            errorFirstNameRequiredMessage: 'Vorname ist erforderlich.',
            errorLastNameRequiredMessage: 'Nachname ist erforderlich.',
            errorEmailRequiredMessage: 'E-Mail ist erforderlich.',
            errorIAmRequiredMessage: '„Ich bin“ ist erforderlich.',
            errorIAmInquiringRequiredMessage: '„Ich frage“ ist erforderlich.',
            errorMessageRequiredMessage: 'Nachricht ist erforderlich.',
            errorInvalidEmailMessage: 'Ungültige E-Mail.',
            formSentTitle: 'E-Mail erfolgreich gesendet!',
            formSentDescription: 'Vielen Dank für Ihre Nachricht! Ihre Nachricht wurde erfolgreich an unser Team gesendet. Ein Hub71-Vertreter wird sich so schnell wie möglich bei Ihnen melden.',
            iAmOptions: ['Einrichtung', 'Investor', 'Unternehmen', 'Regierung', 'Akademisch', 'Medien', 'Freiberufler', 'Sonstiges'],
            iAmInquiringOptions: ['Hub71-Programme', 'Hub71-Bewerbungen', 'Dienstleistungen anbieten', 'Partnerschaften/Kollaborationen',
                'Anfragen zu Abu Dhabi/Geschäftsgründung', 'Veranstaltungen', 'Karriere', 'Delegationen', 'Allgemeines Feedback', 'Allgemeine Anfrage']
        }
    },
    {
        locale: "ru-RU",
        typography: {
            // account
            settings: "Настройки",
            logout: "Выйти",
            // messages
            lostNet: "Извините, похоже, я на данный момент отключен. Пожалуйста, попробуйте снова позже.",
            // landing page translation
            helloHeader: "Привет, я",
            assistHeader: `Как я могу вам помочь сегодня?`,
            // character translate
            bubbleText: [ "Здравствуйте, я ваш цифровой ассистент.", "Нажмите на меня, чтобы начать звонок." ],
            // language names translation
            /*languageNames: { "ar-SA": "Арабский", "zh-CN": "Китайский", "en-US": "Английский", "fr-FR": "Французский", "de-DE": "Немецкий",
                "ru-RU": "Русский", "es-ES": "Испанский", "tr-TR": "Tурецкий", "sr-RS": "Сербский", "sr-Cyrl-RS": "Сербский кириллица" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},
            // controls translation
            currentInputPlaceholder: "Я здесь, чтобы помочь. Как я могу помочь?",
            currentInputPlaceholderMobile: "Задайте вопрос...",
            // feedback translation
            placeholderText: 'Введите дополнительный отзыв...',
            feedbackText: 'Оставьте дополнительный отзыв',
            harmfulCheckboxText: 'Это вредно/небезопасно',
            helpfulCheckboxText: 'Это бесполезно',
            trueCheckBoxText: 'Это неправда',
            buttonText: 'Отправить',
            // tooltips
            tooltipCopy: 'Копировать',
            copiedLabel: 'Скопировано',
            tooltipDownload: 'Скачать',
            downloadedLabel: "Загружено",
            tooltipDislike: 'Плохой ответ',
            tooltipLike: 'Хороший ответ',
            tooltipEdit: 'Редактировать сообщение',
            sendButton: 'Отправить',
            cancelButton: 'Отмена',
            // related content
            relatedContentTitle: 'Связанный контент:',
            relatedQuestionsTitle: 'Исследовать больше',
            quickLinksTitle: 'Быстрые ссылки',
            quickLinks: null,
            newChat: 'Новый чат',
            // contact form
            contactFormLabel: 'Контактная форма',
            firstNameLabel: 'Имя',
            lastNameLabel: 'Фамилия',
            emailLabel: 'Электронная почта',
            iamLabel: 'Я',
            iamInquiringLabel: 'Я спрашиваю',
            messageLabel: 'Ваше сообщение',
            submitButtonLabel: 'Отправить',
            errorFirstNameRequiredMessage: 'Имя обязательно.',
            errorLastNameRequiredMessage: 'Фамилия обязательна.',
            errorEmailRequiredMessage: 'Электронная почта обязательна.',
            errorIAmRequiredMessage: 'Поле "Я есть" обязательно.',
            errorIAmInquiringRequiredMessage: 'Поле "Я спрашиваю" обязательно.',
            errorMessageRequiredMessage: 'Сообщение обязательно.',
            errorInvalidEmailMessage: 'Недопустимый адрес электронной почты.',
            formSentTitle: 'Электронное письмо успешно отправлено!',
            formSentDescription: 'Спасибо, что связались с нами! Ваше сообщение успешно отправлено нашей команде. Представитель Hub71 свяжется с вами как можно скорее.',
            iAmOptions: ['Настройка', 'Инвестор', 'Корпоративный', 'Правительство', 'Академический', 'СМИ', 'Фрилансер', 'Другое'],
            iAmInquiringOptions: ['Программы Hub71', 'Приложения Hub71', 'Предоставление услуг', 'Партнёрство/Сотрудничество',
                'Запросы о Абу-Даби/Создании бизнеса', 'События', 'Карьера', 'Делегации', 'Общий отзыв', 'Общий запрос']
        }
    },
    {
        locale: "es-ES",
        typography: {
            // account
            settings: "Configuración",
            logout: "Cerrar sesión",
            // messages
            lostNet: "Disculpa, parece que estoy desconectado momentáneamente. Por favor, inténtalo de nuevo más tarde",
            // landing page translation
            helloHeader: "Hola, soy",
            assistHeader: `¿Cómo puedo ayudarte hoy?`,
            // character translate
            bubbleText: [ "Hola, soy tu asistente digital.", "Haz clic en mí para iniciar una llamada." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arábica", "zh-CN": "Chino", "en-US": "Inglés", "fr-FR": "Francés", "de-DE": "Alemán",
                "ru-RU": "Ruso", "es-ES": "Español", "tr-TR": "Turco", "sr-RS": "Serbio", "sr-Cyrl-RS": "Serbio Cirílico"},*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},
            // controls translation
            currentInputPlaceholder: "Haz una pregunta...",
            currentInputPlaceholderMobile: "Haz una pregunta...",
            // feedback translation
            placeholderText: 'Escriba sus comentarios adicionales...',
            feedbackText: 'Proporcionar comentarios adicionales',
            harmfulCheckboxText: 'Esto es dañino/inseguro',
            helpfulCheckboxText: 'Esto no es útil',
            trueCheckBoxText: 'Esto no es cierto',
            buttonText: 'Enviar',
            // tooltips
            tooltipCopy: 'Copiar',
            copiedLabel: 'Copiado',
            tooltipDownload: 'Descargar',
            downloadedLabel: "Descargado",
            tooltipDislike: 'Mala respuesta',
            tooltipLike: 'Buena respuesta',
            tooltipEdit: 'Editar mensaje',
            sendButton: 'Enviar',
            cancelButton: 'Cancelar',
            // related content
            relatedContentTitle: 'Contenido Relacionado:',
            relatedQuestionsTitle: 'Explora más',
            quickLinksTitle: 'Enlaces Rápidos',
            quickLinks: null,
            newChat: 'Nuevo Chat',
            // contact form
            contactFormLabel: 'Formulario de contacto',
            firstNameLabel: 'Nombre',
            lastNameLabel: 'Apellido',
            emailLabel: 'Correo electrónico',
            iamLabel: 'Soy',
            iamInquiringLabel: 'Estoy preguntando sobre',
            messageLabel: 'Tu mensaje',
            submitButtonLabel: 'Enviar',
            errorFirstNameRequiredMessage: 'El primer nombre es obligatorio.',
            errorLastNameRequiredMessage: 'El apellido es obligatorio.',
            errorEmailRequiredMessage: 'El correo electrónico es obligatorio.',
            errorIAmRequiredMessage: '“Soy” es obligatorio.',
            errorIAmInquiringRequiredMessage: '“Estoy preguntando” es obligatorio.',
            errorMessageRequiredMessage: 'El mensaje es obligatorio.',
            errorInvalidEmailMessage: 'Correo electrónico inválido.',
            formSentTitle: '¡Correo electrónico enviado con éxito!',
            formSentDescription: '¡Gracias por contactarnos! Su mensaje ha sido enviado exitosamente a nuestro equipo. Un representante de Hub71 se pondrá en contacto con usted lo antes posible.',
            iAmOptions: ['Configuración', 'Inversor', 'Corporativo', 'Gobierno', 'Académico', 'Medios', 'Freelance', 'Otro'],
            iAmInquiringOptions: ['Programas de Hub71', 'Aplicaciones de Hub71', 'Prestación de servicios', 'Asociaciones/Colaboraciones',
                'Consultas sobre Abu Dhabi/Creación de empresas', 'Eventos', 'Carreras', 'Delegaciones', 'Comentarios generales', 'Consulta general']
        }
    },
    {
        locale: "tr-TR",
        typography: {
            // account
            settings: "Ayarlar",
            logout: "Çıkış Yap",
            // messages
            lostNet: "Özür dilerim, şu anda bağlantımın kesildiği görünüyor. Lütfen daha sonra tekrar deneyin.",
            // landing page translation
            helloHeader: "Merhaba, ben",
            assistHeader: `Bugün size nasıl yardımcı olabilirim?`,
            // character translate
            bubbleText: [ "Merhaba, ben dijital asistanınızım.", "Aramayı başlatmak için bana tıkla." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arapça", "zh-CN": "Çince", "en-US": "İngilizce", "fr-FR": "Fransızca", "de-DE": "Almanca",
                "ru-RU": "Rusça", "es-ES": "İspanyol", "tr-TR": "Türkçe", "sr-RS": "Sırpça", "sr-Cyrl-RS": "Sırp Kiril Alfabesi" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},
            // controls translation
            currentInputPlaceholder: "Yardım etmek için buradayım. Size nasıl yardımcı olabilirim?",
            currentInputPlaceholderMobile: "Bir soru sor...",
            // feedback translation
            placeholderText: 'Ek geri bildiriminizi yazın...',
            feedbackText: 'Ek geri bildirim sağlayın',
            harmfulCheckboxText: 'Bu zararlı/güvensiz',
            helpfulCheckboxText: 'Bu yararlı değil',
            trueCheckBoxText: 'Bu doğru değil',
            buttonText: 'Gönder',
            // tooltips
            tooltipCopy: 'Kopyala',
            copiedLabel: 'Kopyalandı',
            tooltipDownload: 'İndir',
            downloadedLabel: "İndirildi",
            tooltipDislike: 'Kötü yanıt',
            tooltipLike: 'İyi yanıt',
            tooltipEdit: 'Mesajı düzenle',
            sendButton: 'Gönder',
            cancelButton: 'İptal',
            // related content
            relatedContentTitle: 'İlgili İçerik:',
            relatedQuestionsTitle: 'Daha fazlasını keşfet',
            quickLinksTitle: 'Hızlı Bağlantılar',
            quickLinks: null,
            newChat: 'Yeni Sohbet',
            // contact form
            contactFormLabel: 'İletişim Formu',
            firstNameLabel: 'Ad',
            lastNameLabel: 'Soyad',
            emailLabel: 'E-posta',
            iamLabel: 'Ben',
            iamInquiringLabel: 'Sorguluyorum',
            messageLabel: 'Mesajınız',
            submitButtonLabel: 'Gönder',
            errorFirstNameRequiredMessage: 'Adınız zorunludur.',
            errorLastNameRequiredMessage: 'Soyadınız zorunludur.',
            errorEmailRequiredMessage: 'E-posta zorunludur.',
            errorIAmRequiredMessage: 'Ben bir/” zorunludur.',
            errorIAmInquiringRequiredMessage: '“Soruyorum” zorunludur.',
            errorMessageRequiredMessage: 'Mesaj zorunludur.',
            errorInvalidEmailMessage: 'Geçersiz e-posta.',
            formSentTitle: 'E-posta başarıyla gönderildi!',
            formSentDescription: 'Bizimle iletişime geçtiğiniz için teşekkür ederiz! Mesajınız ekibimize başarıyla gönderildi. Bir Hub71 temsilcisi en kısa sürede sizinle iletişime geçecektir.',
            iAmOptions: ['Kurulum', 'Yatırımcı', 'Kurumsal', 'Devlet', 'Akademik', 'Medya', 'Serbest Çalışan', 'Diğer'],
            iAmInquiringOptions: ['Hub71 programları', 'Hub71 başvuruları', 'Hizmet sağlama', 'Ortaklıklar/İş birlikleri',
                'Abu Dhabi/İş kurma sorguları', 'Etkinlikler', 'Kariyerler', 'Heyetler', 'Genel geri bildirim', 'Genel sorgu']
        }
    },
    {
        locale: "sr-RS",
        typography: {
            // account
            settings: "Podešavanja",
            logout: "Odjavite se",
            // messages
            lostNet: "Izvinjavam se, izgleda da sam trenutno bez veze. Pokušajte ponovo kasnije.",
            // landing page translation
            helloHeader: "Zdravo, ja sam",
            assistHeader: `Kako mogu da Vam pomognem danas?`,
            // character translate
            bubbleText: [ "Zdravo, ja sam Vaš digitalni asistent.", "Kliknite na mene da započnete poziv." ],
            // language names translation
            /*languageNames: { "ar-SA": "Arapski", "zh-CN": "Kineski", "en-US": "Engleski", "fr-FR": "Francuski",
                "de-DE": "Nemački", "ru-RU": "Ruski", "es-ES": "Španski", "tr-TR": "Turski", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Srpski ćirilica" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},
            // controls translation
            currentInputPlaceholder: "Ovde sam da pomognem. Kako mogu da pomognem?",
            currentInputPlaceholderMobile: "Postavite pitanje...",
            // feedback translation
            placeholderText: 'Unesite dodatne povratne informacije...',
            feedbackText: 'Pružite dodatne povratne informacije',
            harmfulCheckboxText: 'Ovo je štetno/nebezbedno',
            helpfulCheckboxText: 'Ovo nije korisno',
            trueCheckBoxText: 'Ovo nije tačno',
            buttonText: 'Potvrdite',
            // tooltips
            tooltipCopy: 'Kopiraj',
            copiedLabel: 'Kopirano',
            tooltipDownload: 'Preuzmi',
            downloadedLabel: "Preuzeto",
            tooltipDislike: 'Loš odgovor',
            tooltipLike: 'Dobar odgovor',
            tooltipEdit: 'Izmenite poruku',
            sendButton: 'Pošaljite',
            cancelButton: 'Otkažite',
            // related content
            relatedContentTitle: 'Povezani sadržaj:',
            relatedQuestionsTitle: 'Istraži više',
            quickLinks: null,
            newChat: 'Novi Chat',
            // contact form
            contactFormLabel: 'Kontakt Forma',
            firstNameLabel: 'Ime',
            lastNameLabel: 'Prezime',
            emailLabel: 'Email',
            iamLabel: 'Ja sam',
            iamInquiringLabel: 'Ja pitam',
            messageLabel: 'Vaša poruka',
            submitButtonLabel: 'Pošaljite',
            errorFirstNameRequiredMessage: 'Ime je obavezno.',
            errorLastNameRequiredMessage: 'Prezime je obavezno.',
            errorEmailRequiredMessage: 'Email je obavezan.',
            errorIAmRequiredMessage: '„Ja sam“ je obavezno.',
            errorIAmInquiringRequiredMessage: '„Pitam“ je obavezno.',
            errorMessageRequiredMessage: 'Poruka je obavezna.',
            errorInvalidEmailMessage: 'Email nije validan.',
            formSentTitle: 'E-pošta je uspešno poslata!',
            formSentDescription: 'Hvala što ste nas kontaktirali! Vaša poruka je uspešno poslata našem timu. Predstavnik Hub71 će vam se javiti što je pre moguće.',
            iAmOptions: ['Podešavanje', 'Investitor', 'Korporativno', 'Vlada', 'Akademsko', 'Mediji', 'Frilenser', 'Drugo'],
            iAmInquiringOptions: ['Programi Hub71', 'Aplikacije Hub71', 'Pružanje usluga', 'Partnerstva/Saradnje',
                'Upiti o Abu Dabiju/Poslovanju', 'Događaji', 'Karijere', 'Delegacije', 'Opšti utisci', 'Opšti upit']
        }
    },
    {
        locale: "sr-Cyrl-RS",
        typography: {
            // account
            settings: "Подешавања",
            logout: "Одјавите се",
            // messages
            lostNet: "Извињавам се, изгледа да сам тренутно без везе. Покушајте поново касније.",
            // landing page translation
            helloHeader: "Здраво, ја сам",
            assistHeader: `Како могу да Вам помогнем данас?`,
            // character translate
            bubbleText: [ "Здраво, ја сам Ваш дигитални асистент.", "Кликните на мене да започнете позив." ],
            // language names translation
            /*languageNames: { "ar-SA": "Арапски", "zh-CN": "Кинески", "en-US": "Енглески", "fr-FR": "Француски",
                "de-DE": "Немачки", "ru-RU": "Руски", "es-ES": "Шпански", "tr-TR": "Турски", "sr-RS": "Српски",
                "sr-Cyrl-RS": "Српски Ћирилица" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},
            // controls translation
            currentInputPlaceholder: "Овде сам да помогнем. Како могу да помогнем?",
            currentInputPlaceholderMobile: "Поставите питање...",
            // feedback translation
            placeholderText: 'Унесите додатне повратне информације...',
            feedbackText: 'Пружите додатне повратне информације',
            harmfulCheckboxText: 'Ово је штетно/небезбедно',
            helpfulCheckboxText: 'Ово није корисно',
            trueCheckBoxText: 'Ово није тачно',
            buttonText: 'Потврдите',
            // tooltips
            tooltipCopy: 'Копирај',
            copiedLabel: 'Копирано',
            tooltipDownload: 'Преузми',
            downloadedLabel: "Преузето",
            tooltipDislike: 'Лош одговор',
            tooltipLike: 'Добар одговор',
            tooltipEdit: 'Измените поруку',
            sendButton: 'Пошаљите',
            cancelButton: 'Откажите',
            // related content
            relatedContentTitle: 'Повезани садржај:',
            relatedQuestionsTitle: 'Истражи више',
            quickLinksTitle: 'Брзи линкови',
            quickLinks: null,
            newChat: 'Нови Чат',
            // contact form
            contactFormLabel: 'Контакт Форма',
            firstNameLabel: 'Име',
            lastNameLabel: 'Презиме',
            emailLabel: 'Имејл',
            iamLabel: 'Ја сам',
            iamInquiringLabel: 'Ја питам',
            messageLabel: 'Ваша порука',
            submitButtonLabel: 'Пошаљите',
            errorFirstNameRequiredMessage: 'Име је обавезно.',
            errorLastNameRequiredMessage: 'Презиме је обавезно.',
            errorEmailRequiredMessage: 'Емаил је обавезан.',
            errorIAmRequiredMessage: '„Ја сам“ је обавезно.',
            errorIAmInquiringRequiredMessage: '„Питам“ је обавезно.',
            errorMessageRequiredMessage: 'Порука је обавезна.',
            errorInvalidEmailMessage: 'Емаил није валидан.',
            formSentTitle: 'Е-пошта је успешно послата!',
            formSentDescription: 'Хвала што сте нас контактирали! Ваша порука је успешно послата нашем тиму. Представник Hub71 ће вам се јавити што је пре могуће.',
            iAmOptions: ['Подешавање', 'Инвеститор', 'Корпоративно', 'Влада', 'Академско', 'Медији', 'Фриленсер', 'Друго'],
            iAmInquiringOptions: ['Програми Hub71', 'Апликације Hub71', 'Пружање услуга', 'Партнерства/Сарадње',
                'Упити о Абу Дабију/Пословању', 'Догађаји', 'Каријере', 'Делегације', 'Општи утисци', 'Општи упит']
        }
    }
]
