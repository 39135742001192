import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { LanguageService } from "../../../core/services/language.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../core/services/alert.service";
import { MiscService } from "../../../core/services/misc.service";

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrl: './contact-form.component.scss'
})
export class ContactFormComponent implements OnInit, OnDestroy {
    contactFormLabel: string = '';
    firstNameLabel: string = '';
    lastNameLabel: string = '';
    emailLabel: string = '';
    iamLabel: string = '';
    iamInquiringLabel: string = '';
    messageLabel: string = '';
    submitButtonLabel: string = '';
    contactForm!: FormGroup; // Using reactive forms
    iAmOption: string = '';
    iAmInquiringOption: string = '';
    isFormSent: boolean = false;
    isIamDropdownOpen: boolean = false;
    isIamInquiringDropdownOpen: boolean = false;
    errorFirstNameRequiredMessage = '';
    errorLastNameRequiredMessage = '';
    errorEmailRequiredMessage = '';
    errorIAmRequiredMessage = '';
    errorIAmInquiringRequiredMessage = '';
    errorMessageRequiredMessage = '';
    errorInvalidEmailMessage = '';
    formSentTitle = '';
    formSentDescription = '';

    iAmOptions: string[] = [];
    iAmInquiringOptions: string[] = [];

    translateSubscription!: Subscription;

    constructor(private language: LanguageService,
                private alert: AlertService,
                private formBuilder: FormBuilder,
                private misc: MiscService) { }

    ngOnInit(): void {
        this.initializeForm();

        this.translateSubscription = this.language.selectedLanguage$.subscribe((language) => {
            const translate = this.language.getDesignTranslation(language.locale);
            Object.assign(this, translate.typography);
        });
    }

    getContactFormElement(control: string) {
        return this.contactForm.get(control);
    }

    get isFormValid(): boolean {
        const controls = ['firstName', 'lastName', 'email', 'message'];
        return controls.every(controlName => {
            const control = this.contactForm.get(controlName);
            return control && !control.invalid;
        }) && this.iAmOption !== "" && this.iAmInquiringOption !== "";
    }

    private initializeForm() {
        this.contactForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            message: ['', Validators.required]
        });
    }

    isFieldInvalid(controlName: string): boolean {
        const control = this.contactForm.get(controlName);
        return !!(control?.invalid && (control.dirty || this.contactForm.get(controlName)?.touched)); // Parse into boolean type with !!
    }

    resetFields(){
        this.contactForm.reset();
    }

    toggleIamDropdown() {
        this.isIamDropdownOpen = !this.isIamDropdownOpen;
    }

    closeIamDropdown() {
        this.isIamDropdownOpen = false;
    }

    toggleIamInquiringDropdown() {
        this.isIamInquiringDropdownOpen = !this.isIamInquiringDropdownOpen;
    }

    closeIamInquiringDropdown() {
        this.isIamInquiringDropdownOpen = false;
    }

    selectIamOption(option: string) {
        this.iAmOption = option;
        this.isIamDropdownOpen = false;
    }

    selectIamInquiringOption(option: string) {
        this.iAmInquiringOption = option;
        this.isIamInquiringDropdownOpen = false;
    }

    async submitForm() {
        this.alert.showLoading();
        //const formData = new FormData();

        const form = {
            firstName: this.contactForm.get('firstName')?.value,
            lastName: this.contactForm.get('lastName')?.value,
            email: this.contactForm.get('email')?.value,
            iam: this.iAmOption,
            iamInquiring: this.iAmInquiringOption,
            message: this.contactForm.get('message')?.value
        }

        const contactFormResponse = await this.misc.sendContactForm(form);

        if (contactFormResponse.status !== 200) {
            this.alert.showError('Error', `Error while sending contact form.`)
            return;
        } else {
            this.alert.close();
            this.isFormSent = true;
        }
    }

    ngOnDestroy(): void {
        this.translateSubscription.unsubscribe();
    }
}
