import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";

import { InteractionService } from "../../../core/services/interaction.service";
import { LanguageService } from "../../../core/services/language.service";
import { MultimediaService } from "../../../core/services/multimedia.service";
import { MessageService } from "../../../core/services/message.service";
import { AttachmentDto } from "../../../core/dtos/attachmentDto";
import { EventService } from "../../../core/services/event.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { AnimationService } from "../../../core/services/animation.service";
import { AlertService } from "../../../core/services/alert.service";

@Component({
  selector: 'app-conversation-controls',
  templateUrl: './conversation-controls.component.html',
  styleUrl: './conversation-controls.component.scss'
})
export class ConversationControlsComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;
    @ViewChild('textarea') textareaRef!: ElementRef<HTMLTextAreaElement>;

    currentInputPlaceholder: string = '';

    currentInputValue!: string;
    isInteractionAllowed: boolean = false;
    isInputShown: boolean = true;
    files: AttachmentDto[] = [];
    translate: any;

    translateSubscription!: Subscription;
    interactionSubscription!: Subscription;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private multimedia: MultimediaService,
        private message: MessageService,
        private language: LanguageService,
        private interaction: InteractionService,
        private event: EventService,
        private visibility: VisibilityService,
        private animation: AnimationService,
        private alert: AlertService
    ) { }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.updatePlaceholder(event.target.innerWidth);
    }

    ngOnInit() {
        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            this.translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.updatePlaceholder(window.innerWidth);
        });

        this.animation.startDefaultAnimation();
        this.visibility.showComponent('avatar-input')
    }

    // getters
    getCurrentInputValue (): string {
        return this.currentInputValue;
    }

    getAttachmentExtension(file: AttachmentDto): string | undefined {
        if (file?.name) {
            const parts = file.name.split('.');
            return parts.length > 1 ? parts.pop()?.toUpperCase() : undefined;
        }
        return undefined;
    }

    getExtension(fileName: string) {
        return fileName.split('.').pop()!.toUpperCase();
    }

    isInputFilled(): boolean {
        const input = this.getCurrentInputValue();
        return input !== undefined && input.trim() !== '';
    }

    // events
    onOpenAttachments() {
        this.fileInputRef.nativeElement.click();
    }

    hasUploadFiles() {
        return this.files.length > 0;
    }

    onFileSelect(event: any): void {
        const maxSize = 50 * 1024 * 1024; // 50 MB
        const files = event.target.files;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size <= maxSize) {
                this.handleDroppedFiles(file);
            } else {
                this.alert.showError('Error', `File exceeds the 50MB size limit and will not be uploaded.`)
            }
        }

        this.fileInputRef.nativeElement.value = '';
    }

    handleDroppedFiles(file: File | {}) {
        if (file instanceof File) {
            const type = this.multimedia.getAttachmentType(file.name);
            const url = URL.createObjectURL(file);

            // We need to make file with lowercase bcs of BE restrictions
            const sanitizedFileName = file.name.replace(/\s+/g, '_');
            const fileLowerCaseSanitezed = new File([file], sanitizedFileName.toLowerCase(), { type: file.type });

            // Prepare attachments to display into conversation control input
            const fileDto = new AttachmentDto(file.name, file.size, url, type, fileLowerCaseSanitezed);
            this.files.push(fileDto);
        }
    }

    updatePlaceholder(screenWidth: number) {
        if (screenWidth < 993) {
            this.currentInputPlaceholder = this.translate.typography.currentInputPlaceholderMobile;
        } else {
            this.currentInputPlaceholder =  this.translate.typography.currentInputPlaceholder;
        }

        this.changeDetector.detectChanges();
    }

    async onEnterKey(event: any) {
        if (this.isInputFilled() || this.hasUploadFiles()) {
            await this.onSubmitMessage();
        } else {
            event.preventDefault();
        }
    }

    async onSubmitMessage() {
        if (this.textareaRef && this.textareaRef.nativeElement) {
            this.textareaRef.nativeElement.focus();
        }

        const input = this.getCurrentInputValue();

        if (this.files && this.files.length > 0) {
            this.alert.showLoading();
        }

        await this.message.createConversationRequest("text", "text", input, '', this.files);

        this.alert.close();

        this.resetTextarea();
        this.removeFiles();
    }

    onInput(): void {
        this.event.scrollToBottomEvent.emit();
        const textareaElem = this.textareaRef.nativeElement;
        textareaElem.style.height = '26px';
        const scHeight = textareaElem.scrollHeight;
        textareaElem.style.height = `${scHeight}px`;
    }

    resetTextarea() {
        this.currentInputValue = "";

        const textareaElem = this.textareaRef.nativeElement;
        textareaElem.style.height = '26px';
    }

    removeFiles() {
        this.files = [];
    }

    removeFile(index: number) {
        this.files.splice(index, 1);
    }

    ngOnDestroy() {
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
    }
}
