<div class="box-navbar">
    <div class="navbar-container">
        <a href="https://hub71.com">Homepage</a>
        <a>></a>
        <a>Contact us</a>
        <div *ngIf="isNewChatButtonVisible" class="button-container">
            <button id="id-new-chat" type="button" class="new-chat-button" (click)="newChat()" [disabled]="!isInteractionAllowed">
                <app-plus></app-plus>
                <p class="new-chat-text">{{ newChatButton }}</p>
            </button>
        </div>
    </div>
</div>
